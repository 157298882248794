export const Constant = {
    URL_PERMISSION: {
        das_view: 'dashboard',
        db_view: 'dashboard',
        pro_view: 'program',
        grp_view: 'member-group',
        ac_view: 'accrual-event',
        rw_view: 'reward',
        rwf_view: 'reward-fulfillment',
        rwu_view: 'reward-usage',
        rwc_view: 'reward-category',
        rwi_view: 'reward-inventory',
        wh_view: 'warehouse',
        mp_view: 'manual-point',
        ptc_view: "point-to-currency",
        us_view: 'users',
        ro_view: 'roles',
        or_view: 'organization/list',
        ot_view: 'organization/type',
        ss_view: 'settings/system-setting',
        os_view: 'settings/system-setting',
        mas_view: "master-data",
        
    },
    ORDER_VALUE: "ORDER_VALUE",
    NUMBER_OF_BOUGHT_VALUE: "NUMBER_OF_BOUGHT_VALUE",
    URL_GET_IMAGE: "/api/v1/file/"
}