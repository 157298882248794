import { ErrorHandler } from "@angular/core";
import { Injectable } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
  handleError(error: any): void {
    const chunkFailedMessage = "ChunkLoadError: Loading chunk";
    if(error && error.message && error.message.includes(chunkFailedMessage)) {
         window.location.reload();
    }
  }
}