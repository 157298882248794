/**
 * Integration Service Engine API
 * test
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SourceSystemType = 'Shopify' | 'OrderCloud' | 'WarrantySystem';

export const SourceSystemType = {
    Shopify: 'Shopify' as SourceSystemType,
    OrderCloud: 'OrderCloud' as SourceSystemType,
    WarrantySystem: 'WarrantySystem' as SourceSystemType
};

