import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DataStoreService } from './data-store.service';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private refreshingInProgress: boolean;
  private userId: string;
  constructor(
    private authService: AuthService,
    private dataStoreService: DataStoreService,
    private messageService: MessageService
    ) {}

  needToInjectToken(req: HttpRequest<any>) {
    const haveBearer = req.headers.get('Authorization');
    if (req.url.endsWith('/api/v1/token') || req.url.includes('/api/v1/reward-store-site/token/') ||
    window.location.href.includes('/login')) {
      return false;
    }
    return haveBearer == null || haveBearer.trim().length == 0;
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const haveBearer = req.headers.get('Authorization');
    const userToken: {
      access_token: string,
      refresh_token: string
    } = JSON.parse(localStorage.getItem(window.location.href.includes("web-store")  ? 'rwdStoreAccessToken' : 'claAccessToken'))
    let user: any = {};
    if (userToken && userToken.access_token) {
      user = this.authService.getNewUserInfo(userToken.access_token);
    }
    const accessToken = userToken && userToken.access_token;
    const refreshToken = userToken && userToken.refresh_token;
    if (this.needToInjectToken(req)) {
      req = this.injectToken(req, accessToken);
    }
    return next.handle(req).pipe(
      switchMap((res: any) => {
        const { body } = res;
        const { error_code = '', status = '' } = body || {};
        if(status === 'error') {
          if (error_code === 'TOKEN_EXPIRED') {
            return this.refreshToken(req, next);
          }
          if (
            error_code === 'REFRESH_TOKEN_EXPIRED' ||
            error_code === 'REFRESH_TOKEN_EMPTY' ||
            error_code === 'TIME_OUT' ||
            error_code === 'password_changed'
            ) {
              if(window.location.href.includes("web-store")) {
                window.location.replace(user.originLoginUrl);
                return throwError(body);
              }
              return this.logoutAndRedirect(body);
          }
        }

        return of(res);
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 0) {
          return throwError({
            status: 'error',
            message: 'UNKNOWN_ERROR'
          });
        }

        if (err instanceof HttpErrorResponse && err.status === 500) {
          return throwError({
            status: 'error',
            message: err.error.message
          });
          // return throwError({
          //   status: 'error',
          //   message: 'INTERNAL_SERVER_ERROR'
          // });
        }
        else if (err instanceof HttpErrorResponse && err.status == 401) {
          if (window.location.href.includes("web-store")) {
            this.messageService.setMessages401({
              message: "program_reward_system.common.error_401",
              success: false,
            });
          }
          if (accessToken && this.authService.isTokenExpired(accessToken)) {
            let sessionExpired = true;
            try {
              sessionExpired = this.authService.isTokenExpired(refreshToken);
            } catch (ex) {
            }
            if (!window.location.href.includes("web-store")) {
              if (sessionExpired) {
                return this.logoutAndRedirect({message: 'Session expired'});
              } else {
                return this.refreshToken(req, next);
              }
            } else {
              if (!sessionExpired) {
                return this.refreshToken(req, next);
              }
            }
          }
        }

        return throwError(err.error || err);
      })
    );
  }

  private injectToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }
    return request;
  }

  private logoutAndRedirect(err?): Observable<HttpEvent<any>> {
    this.authService.logout(true, window.location.pathname);
    return throwError(err);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      const userToken: {
        access_token: string,
        refresh_token: string
      } = JSON.parse(localStorage.getItem(this.userId ? 'rwdStoreAccessToken' : 'claAccessToken'))
      const accessToken = userToken && userToken.access_token;
      const refreshToken = userToken && userToken.refresh_token;
      this.refreshingInProgress = true;
      return this.authService.refreshToken(this.authService.getNewUserInfo(accessToken).email, refreshToken).pipe(
        switchMap(
          (res: any) => {
            this.refreshingInProgress = false;
            return next.handle(this.injectToken(request, res.access_token));
          }
        )
      );
    } else {
      return this.authService.user.pipe(
        switchMap(token => {
          return next.handle(this.injectToken(request, token.access_token));
        }));
    }
  }
}
