import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class ExchangeTokenService {

    constructor(private apiService: ApiService) { }

    exchangeTokenFACESToCLA(userId: string) {
        const urlExchangeTokenFACESToCLA = "user/{userId}/token/loyalty"
        const url = environment.AUTHENTICATION_ENDPOINT + urlExchangeTokenFACESToCLA.replace('{userId}', userId);
        return this.apiService.get(url).pipe(map(res => res.body));
    }
}
