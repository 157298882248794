export * from './backofficeSourcesystem.service';
import { BackofficeSourcesystemService } from './backofficeSourcesystem.service';
export * from './backofficeSourcesystems.service';
import { BackofficeSourcesystemsService } from './backofficeSourcesystems.service';
export * from './deferredAccrualEventScheduler.service';
import { DeferredAccrualEventSchedulerService } from './deferredAccrualEventScheduler.service';
export * from './emailTemplateAll.service';
import { EmailTemplateAllService } from './emailTemplateAll.service';
export * from './emailTemplateDetail.service';
import { EmailTemplateDetailService } from './emailTemplateDetail.service';
export * from './extensionEntries.service';
import { ExtensionEntriesService } from './extensionEntries.service';
export * from './getAllProductCategories.service';
import { GetAllProductCategoriesService } from './getAllProductCategories.service';
export * from './getAllProducts.service';
import { GetAllProductsService } from './getAllProducts.service';
export * from './getAllSeries.service';
import { GetAllSeriesService } from './getAllSeries.service';
export * from './memberId.service';
import { MemberIdService } from './memberId.service';
export * from './members.service';
import { MembersService } from './members.service';
export * from './promotion.service';
import { PromotionService } from './promotion.service';
export * from './rewardStoreMember.service';
import { RewardStoreMemberService } from './rewardStoreMember.service';
export * from './rewardStoreSiteToken.service';
import { RewardStoreSiteTokenService } from './rewardStoreSiteToken.service';
export * from './rewardStoreSiteTokenTenantId.service';
import { RewardStoreSiteTokenTenantIdService } from './rewardStoreSiteTokenTenantId.service';
export const APIS = [BackofficeSourcesystemService, BackofficeSourcesystemsService, DeferredAccrualEventSchedulerService, EmailTemplateAllService, EmailTemplateDetailService, ExtensionEntriesService, GetAllProductCategoriesService, GetAllProductsService, GetAllSeriesService, MemberIdService, MembersService, PromotionService, RewardStoreMemberService, RewardStoreSiteTokenService, RewardStoreSiteTokenTenantIdService];
