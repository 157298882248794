import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PendingChangesGuard } from './service/check-leave.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LyHammerGestureConfig, LyThemeModule, LY_THEME, LY_THEME_NAME, StyleRenderer, LyTheme2 } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { CoreModule } from './core/core.module';
import { ExchangeTokenService } from './service/token-exchange-faces-to-cla.service';
import { GlobalErrorHandler } from './service/pwa.service';
import { ErrorHandler } from "@angular/core";
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgbModule,
    HammerModule
  ],
  providers: [ExchangeTokenService, PendingChangesGuard, { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, 
    StyleRenderer, LyTheme2, { provide: LY_THEME_NAME, useValue: 'minima-light' }, { provide: LY_THEME, useClass: MinimaLight, 
      multi: true },{provide: ErrorHandler, useClass: GlobalErrorHandler}
] ,
  bootstrap: [AppComponent]
})
export class AppModule { }
