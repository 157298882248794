/**
 * Order Service Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponse } from '../model/booleanResponse';
// @ts-ignore
import { FulfillmentExportType } from '../model/fulfillmentExportType';
// @ts-ignore
import { FulfillmentItem } from '../model/fulfillmentItem';
// @ts-ignore
import { FulfillmentItemDetail } from '../model/fulfillmentItemDetail';
// @ts-ignore
import { FulfillmentItemDetailStatus } from '../model/fulfillmentItemDetailStatus';
// @ts-ignore
import { FulfillmentItemDetailTrackingGetAll } from '../model/fulfillmentItemDetailTrackingGetAll';
// @ts-ignore
import { FulfillmentItemGetAll } from '../model/fulfillmentItemGetAll';
// @ts-ignore
import { FulfillmentStatusUpdateRequestBody } from '../model/fulfillmentStatusUpdateRequestBody';
// @ts-ignore
import { GetAllFulfillmentCriteria } from '../model/getAllFulfillmentCriteria';
// @ts-ignore
import { GetAllRedemptionHistory } from '../model/getAllRedemptionHistory';
// @ts-ignore
import { GetFulfillmentItemByOrderIdFulfillmentIdRewardId } from '../model/getFulfillmentItemByOrderIdFulfillmentIdRewardId';
// @ts-ignore
import { ImportFulfillmentResponse } from '../model/importFulfillmentResponse';
// @ts-ignore
import { MemberGetAll } from '../model/memberGetAll';
// @ts-ignore
import { RestError } from '../model/restError';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

    protected basePath = 'http://localhost:19098/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * export all fulfillment item
     * @param xSAITENANT 
     * @param fromDate fromDate in millisecond
     * @param toDate toDate in millisecond
     * @param expireDateFrom expireDateFrom in millisecond
     * @param expireDateTo expireDateTo in millisecond
     * @param emailAddress emailAddress filter
     * @param memberId memberId filter
     * @param status status filter
     * @param fulfillmentId fulfillmentId filter
     * @param redemptionId redemptionId filter
     * @param orderId orderId filter
     * @param redemptionCenterId redemptionCenterId filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportAllFulfillmentItem(xSAITENANT: string, fromDate: number, toDate: number, expireDateFrom?: number, expireDateTo?: number, emailAddress?: Array<string>, memberId?: Array<string>, status?: Array<FulfillmentItemDetailStatus>, fulfillmentId?: Array<string>, redemptionId?: Array<string>, orderId?: Array<string>, redemptionCenterId?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public exportAllFulfillmentItem(xSAITENANT: string, fromDate: number, toDate: number, expireDateFrom?: number, expireDateTo?: number, emailAddress?: Array<string>, memberId?: Array<string>, status?: Array<FulfillmentItemDetailStatus>, fulfillmentId?: Array<string>, redemptionId?: Array<string>, orderId?: Array<string>, redemptionCenterId?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public exportAllFulfillmentItem(xSAITENANT: string, fromDate: number, toDate: number, expireDateFrom?: number, expireDateTo?: number, emailAddress?: Array<string>, memberId?: Array<string>, status?: Array<FulfillmentItemDetailStatus>, fulfillmentId?: Array<string>, redemptionId?: Array<string>, orderId?: Array<string>, redemptionCenterId?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public exportAllFulfillmentItem(xSAITENANT: string, fromDate: number, toDate: number, expireDateFrom?: number, expireDateTo?: number, emailAddress?: Array<string>, memberId?: Array<string>, status?: Array<FulfillmentItemDetailStatus>, fulfillmentId?: Array<string>, redemptionId?: Array<string>, orderId?: Array<string>, redemptionCenterId?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling exportAllFulfillmentItem.');
        }
        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling exportAllFulfillmentItem.');
        }
        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling exportAllFulfillmentItem.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }
        if (expireDateFrom !== undefined && expireDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expireDateFrom, 'expireDateFrom');
        }
        if (expireDateTo !== undefined && expireDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expireDateTo, 'expireDateTo');
        }
        if (emailAddress) {
            emailAddress.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'emailAddress');
            })
        }
        if (memberId) {
            memberId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'memberId');
            })
        }
        if (status) {
            status.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'status');
            })
        }
        if (fulfillmentId) {
            fulfillmentId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fulfillmentId');
            })
        }
        if (redemptionId) {
            redemptionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'redemptionId');
            })
        }
        if (orderId) {
            orderId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderId');
            })
        }
        if (redemptionCenterId) {
            redemptionCenterId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'redemptionCenterId');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/fulfillment/export`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Fulfillment Criteria
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param status 
     * @param search search in name list
     * @param searchBy search stock history by column
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFulfillmentCriteria(xSAITENANT: string, xSAILANG: string, status: FulfillmentExportType, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetAllFulfillmentCriteria>;
    public getAllFulfillmentCriteria(xSAITENANT: string, xSAILANG: string, status: FulfillmentExportType, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetAllFulfillmentCriteria>>;
    public getAllFulfillmentCriteria(xSAITENANT: string, xSAILANG: string, status: FulfillmentExportType, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetAllFulfillmentCriteria>>;
    public getAllFulfillmentCriteria(xSAITENANT: string, xSAILANG: string, status: FulfillmentExportType, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllFulfillmentCriteria.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getAllFulfillmentCriteria.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getAllFulfillmentCriteria.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (searchBy !== undefined && searchBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchBy, 'searchBy');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/criteria`;
        return this.httpClient.request<GetAllFulfillmentCriteria>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all fulfillment item
     * @param xSAITENANT 
     * @param search search by redemption id or fulfillment id
     * @param status search reward by column
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFulfillmentItem(xSAITENANT: string, search?: string, status?: Array<FulfillmentItemDetailStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FulfillmentItemGetAll>;
    public getAllFulfillmentItem(xSAITENANT: string, search?: string, status?: Array<FulfillmentItemDetailStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FulfillmentItemGetAll>>;
    public getAllFulfillmentItem(xSAITENANT: string, search?: string, status?: Array<FulfillmentItemDetailStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FulfillmentItemGetAll>>;
    public getAllFulfillmentItem(xSAITENANT: string, search?: string, status?: Array<FulfillmentItemDetailStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllFulfillmentItem.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (status) {
            status.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'status');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment`;
        return this.httpClient.request<FulfillmentItemGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Preview import all fulfillment item
     * @param xSAITENANT 
     * @param id 
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFulfillmentItemPreview(xSAITENANT: string, id: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FulfillmentItemGetAll>;
    public getAllFulfillmentItemPreview(xSAITENANT: string, id: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FulfillmentItemGetAll>>;
    public getAllFulfillmentItemPreview(xSAITENANT: string, id: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FulfillmentItemGetAll>>;
    public getAllFulfillmentItemPreview(xSAITENANT: string, id: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllFulfillmentItemPreview.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllFulfillmentItemPreview.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/import/preview/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<FulfillmentItemGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Member Email Filters
     * @param xSAITENANT 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMemberEmailCriteria(xSAITENANT: string, type?: 'FULFILLMENT' | 'REWARD_USAGE' | '', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MemberGetAll>;
    public getAllMemberEmailCriteria(xSAITENANT: string, type?: 'FULFILLMENT' | 'REWARD_USAGE' | '', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MemberGetAll>>;
    public getAllMemberEmailCriteria(xSAITENANT: string, type?: 'FULFILLMENT' | 'REWARD_USAGE' | '', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MemberGetAll>>;
    public getAllMemberEmailCriteria(xSAITENANT: string, type?: 'FULFILLMENT' | 'REWARD_USAGE' | '', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllMemberEmailCriteria.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/member-criteria`;
        return this.httpClient.request<MemberGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get csv fulfillment template
     * @param xSAITENANT 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCSVTemplate(xSAITENANT: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<Blob>;
    public getCSVTemplate(xSAITENANT: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getCSVTemplate(xSAITENANT: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getCSVTemplate(xSAITENANT: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv' | 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getCSVTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/fulfillment/import/template`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all fulfillment item History
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param fulfillmentId 
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFulfillmentHistoryById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FulfillmentItemDetailTrackingGetAll>;
    public getFulfillmentHistoryById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FulfillmentItemDetailTrackingGetAll>>;
    public getFulfillmentHistoryById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FulfillmentItemDetailTrackingGetAll>>;
    public getFulfillmentHistoryById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getFulfillmentHistoryById.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getFulfillmentHistoryById.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/${this.configuration.encodeParam({name: "fulfillmentId", value: fulfillmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/history`;
        return this.httpClient.request<FulfillmentItemDetailTrackingGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all fulfillment item
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param fulfillmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFulfillmentItemById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FulfillmentItem>;
    public getFulfillmentItemById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FulfillmentItem>>;
    public getFulfillmentItemById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FulfillmentItem>>;
    public getFulfillmentItemById(xSAITENANT: string, xSAILANG: string, fulfillmentId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getFulfillmentItemById.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getFulfillmentItemById.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/${this.configuration.encodeParam({name: "fulfillmentId", value: fulfillmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<FulfillmentItem>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get fulfillment item details by orderId, fulfillmentId and rewardId.
     * @param xSAITENANT 
     * @param getFulfillmentItemByOrderIdFulfillmentIdRewardId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFulfillmentItemByOrderIdFulfillmentIdRewardId(xSAITENANT: string, getFulfillmentItemByOrderIdFulfillmentIdRewardId?: Array<GetFulfillmentItemByOrderIdFulfillmentIdRewardId>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FulfillmentItemDetail>>;
    public getFulfillmentItemByOrderIdFulfillmentIdRewardId(xSAITENANT: string, getFulfillmentItemByOrderIdFulfillmentIdRewardId?: Array<GetFulfillmentItemByOrderIdFulfillmentIdRewardId>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FulfillmentItemDetail>>>;
    public getFulfillmentItemByOrderIdFulfillmentIdRewardId(xSAITENANT: string, getFulfillmentItemByOrderIdFulfillmentIdRewardId?: Array<GetFulfillmentItemByOrderIdFulfillmentIdRewardId>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FulfillmentItemDetail>>>;
    public getFulfillmentItemByOrderIdFulfillmentIdRewardId(xSAITENANT: string, getFulfillmentItemByOrderIdFulfillmentIdRewardId?: Array<GetFulfillmentItemByOrderIdFulfillmentIdRewardId>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getFulfillmentItemByOrderIdFulfillmentIdRewardId.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/get-status-details-list`;
        return this.httpClient.request<Array<FulfillmentItemDetail>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getFulfillmentItemByOrderIdFulfillmentIdRewardId,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Redemption Histories  with lang code
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param memberId 
     * @param fromDate 
     * @param toDate 
     * @param search search stock history with value
     * @param searchBy search stock history by column
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRedemptionHistories(xSAITENANT: string, xSAILANG: string, memberId: string, fromDate?: number, toDate?: number, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: 'claimedAt' | 'expiredAt' | 'appliedAt' | 'name' | 'fi.datetime', sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetAllRedemptionHistory>;
    public getRedemptionHistories(xSAITENANT: string, xSAILANG: string, memberId: string, fromDate?: number, toDate?: number, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: 'claimedAt' | 'expiredAt' | 'appliedAt' | 'name' | 'fi.datetime', sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetAllRedemptionHistory>>;
    public getRedemptionHistories(xSAITENANT: string, xSAILANG: string, memberId: string, fromDate?: number, toDate?: number, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: 'claimedAt' | 'expiredAt' | 'appliedAt' | 'name' | 'fi.datetime', sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetAllRedemptionHistory>>;
    public getRedemptionHistories(xSAITENANT: string, xSAILANG: string, memberId: string, fromDate?: number, toDate?: number, search?: string, searchBy?: string, page?: number, size?: number, sortBy?: 'claimedAt' | 'expiredAt' | 'appliedAt' | 'name' | 'fi.datetime', sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getRedemptionHistories.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getRedemptionHistories.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling getRedemptionHistories.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (searchBy !== undefined && searchBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchBy, 'searchBy');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/redemption-history/${this.configuration.encodeParam({name: "memberId", value: memberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GetAllRedemptionHistory>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * import all fulfillment item
     * @param xSAITENANT 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAllFulfillmentItem(xSAITENANT: string, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BooleanResponse>;
    public importAllFulfillmentItem(xSAITENANT: string, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponse>>;
    public importAllFulfillmentItem(xSAITENANT: string, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponse>>;
    public importAllFulfillmentItem(xSAITENANT: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling importAllFulfillmentItem.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/import`;
        return this.httpClient.request<BooleanResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Preview import all fulfillment item
     * @param xSAITENANT 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAllFulfillmentItemPreview(xSAITENANT: string, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ImportFulfillmentResponse>;
    public importAllFulfillmentItemPreview(xSAITENANT: string, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ImportFulfillmentResponse>>;
    public importAllFulfillmentItemPreview(xSAITENANT: string, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ImportFulfillmentResponse>>;
    public importAllFulfillmentItemPreview(xSAITENANT: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling importAllFulfillmentItemPreview.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/import/preview`;
        return this.httpClient.request<ImportFulfillmentResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update fulfillment item status
     * @param xSAITENANT 
     * @param memberId 
     * @param orderId 
     * @param fulfillmentId 
     * @param fulfillmentStatusUpdateRequestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFulfillmentStatusByIds(xSAITENANT: string, memberId?: string, orderId?: string, fulfillmentId?: string, fulfillmentStatusUpdateRequestBody?: Array<FulfillmentStatusUpdateRequestBody>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BooleanResponse>;
    public updateFulfillmentStatusByIds(xSAITENANT: string, memberId?: string, orderId?: string, fulfillmentId?: string, fulfillmentStatusUpdateRequestBody?: Array<FulfillmentStatusUpdateRequestBody>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponse>>;
    public updateFulfillmentStatusByIds(xSAITENANT: string, memberId?: string, orderId?: string, fulfillmentId?: string, fulfillmentStatusUpdateRequestBody?: Array<FulfillmentStatusUpdateRequestBody>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponse>>;
    public updateFulfillmentStatusByIds(xSAITENANT: string, memberId?: string, orderId?: string, fulfillmentId?: string, fulfillmentStatusUpdateRequestBody?: Array<FulfillmentStatusUpdateRequestBody>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling updateFulfillmentStatusByIds.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fulfillment/${this.configuration.encodeParam({name: "memberId", value: memberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "fulfillmentId", value: fulfillmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BooleanResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fulfillmentStatusUpdateRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
