import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch:'full'},
  {
    path: '',
    loadChildren: () =>
      import("./layout/cla-system/public/public.module").then(m => m.PublicModule)
  },
  {
    path: '',
    loadChildren: () =>
      import("./layout/cla-system/secure/secure.module").then(
        m => m.SecureModule
      )
  },
  {
    path: 'web-store',
    loadChildren: () =>
      import("./layout/program-reward-system/secure-program-reward/secure-program-reward.module").then(
        m => m.SecureProgramRewardModule
      )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
