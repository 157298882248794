import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrderPlaced } from '../shared/model/order-placed.model';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  // user data
  private _curUserId: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly curUserId: Observable<string> = this._curUserId.asObservable();

  private _curUserFirstName: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly curUserFirstName: Observable<string> = this._curUserFirstName.asObservable();

  private _curUserFullName: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly curUserFullName: Observable<string> = this._curUserFullName.asObservable();

  // point
  private _curUserPoint: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly curUserPoint: Observable<number> = this._curUserPoint.asObservable();

  private _curAccPoint: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly curAccPoint: Observable<number> = this._curAccPoint.asObservable();

  private _curIsShowRewardDetails: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsShowRewardDetails: Observable<boolean> = this._curIsShowRewardDetails.asObservable();

  private _curIsMiniCart: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsMiniCart: Observable<boolean> = this._curIsMiniCart.asObservable();

  private _curIsLoadingCommon: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsLoadingCommon: Observable<boolean> = this._curIsLoadingCommon.asObservable();

  private _curIsLoadingAPI: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsLoadingAPI: Observable<boolean> = this._curIsLoadingAPI.asObservable();

  // list cart
  private _curListCart: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly curListCart: Observable<any> = this._curListCart.asObservable();

  // order placed
  private _curOrderPlaced: BehaviorSubject<IOrderPlaced> = new BehaviorSubject({});
  public readonly curOrderPlaced: Observable<IOrderPlaced> = this._curOrderPlaced.asObservable();

  // reset point
  private _curResetPoint: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curResetPoint: Observable<boolean> = this._curResetPoint.asObservable();

  private _curCurrencyName: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly curCurrencyName: Observable<string> = this._curCurrencyName.asObservable();

  // program active
  private _curProgram: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly curProgram: Observable<any> = this._curProgram.asObservable();

  private _curProgramId: BehaviorSubject<any> = new BehaviorSubject('');
  public readonly curProgramId: Observable<any> = this._curProgramId.asObservable();

  // popup warning reward item deleted
  private _curRewardsDeleted: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public readonly curRewardsDeleted: Observable<any[]> = this._curRewardsDeleted.asObservable();

  // cart id
  private _curCartId: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly curCartId: Observable<any> = this._curCartId.asObservable();

  // tier id
  private _curTierId: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly curTierId: Observable<any> = this._curTierId.asObservable();

  // tier id
  private _curStockSetting: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly curStockSetting: Observable<any> = this._curStockSetting.asObservable();

  // language default setting
  private _curLanguageDefaultSetting: BehaviorSubject<any> = new BehaviorSubject('');
  public readonly curLanguageDefaultSetting: Observable<any> = this._curLanguageDefaultSetting.asObservable();

  // hidden menu
  private _curIsHiddenMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsHiddenMenu: Observable<boolean> = this._curIsHiddenMenu.asObservable();

  // scroll iframe
  private _curChangeLayoutIframe: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curChangeLayoutIframe: Observable<boolean> = this._curChangeLayoutIframe.asObservable();

  // scrollTop
  private _curChangeLayoutIframeTop: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly curChangeLayoutIframeTop: Observable<any> = this._curChangeLayoutIframeTop.asObservable();

  // RemoveReward
  private _curRemoveReward: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly curRemoveReward: Observable<any> = this._curRemoveReward.asObservable();

  // MessageError
  private _curMessageError: BehaviorSubject<any> = new BehaviorSubject("");
  public readonly curMessageError: Observable<any> = this._curMessageError.asObservable();

  private _curOldFilterUsage: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly curOldFilterUsage: Observable<any> = this._curOldFilterUsage.asObservable();

  private _curIsBackBrowser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsBackBrowser: Observable<boolean> = this._curIsBackBrowser.asObservable();

  private _curIsLoadingExchangeToken: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly curIsLoadingExchangeToken: Observable<boolean> = this._curIsLoadingExchangeToken.asObservable();

  private _curIsLoadingRemoveCart: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsLoadingRemoveCart: Observable<boolean> = this._curIsLoadingRemoveCart.asObservable();

  private _curIsLoadingAddToCart: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly curIsLoadingAddToCart: Observable<boolean> = this._curIsLoadingAddToCart.asObservable();

  constructor() { }

  setCurUserData(userId: string) {
    this._curUserId.next(userId);
  }

  setCurUserFirstName(userFirstName: string) {
    this._curUserFirstName.next(userFirstName);
  }

  setCurUserFullName(userFullName: string) {
    this._curUserFullName.next(userFullName);
  }

  setCurUserPoint(point: number) {
    this._curUserPoint.next(point);
  }

  setCurAccPoint(point: number) {
    this._curAccPoint.next(point);
  }

  setCurIsShowRewardDetails(isShow: boolean) {
    this._curIsShowRewardDetails.next(isShow);
  }

  setCurIsMiniCart(isShow: boolean) {
    this._curIsMiniCart.next(isShow);
  }

  setCurIsLoadingCommon(isLoading: boolean) {
    this._curIsLoadingCommon.next(isLoading);
  }

  setCurIsLoadingAPI(isLoading: boolean) {
    this._curIsLoadingAPI.next(isLoading);
  }

  //list cart 
  setCurListCart(listCart: any) {
    this._curListCart.next(listCart);
  }

  // order placed
  setCurOrderPlaced(order: IOrderPlaced) {
    this._curOrderPlaced.next(order);
  }

  // reset point
  setCurResetPoint(isReset: boolean) {
    this._curResetPoint.next(isReset);
  }

  // reset point
  setCurCurrencyName(currencyName: string) {
    this._curCurrencyName.next(currencyName);
  }

  // program active 
  setCurProgram(program: any) {
    this._curProgram.next(program);
  }

  setCurProgramId(programId: any) {
    this._curProgramId.next(programId);
  }

  setCurRewardsDeleted(rewards: any[]) {
    this._curRewardsDeleted.next(rewards);
  }

  setCurCartId(id: any) {
    this._curCartId.next(id);
  }

  setCurTierId(id: any) {
    this._curTierId.next(id);
  }

  setCurStock(data: any) {
    this._curStockSetting.next(data);
  }

  setCurLanguageDefaultSetting(langCode: string) {
    this._curLanguageDefaultSetting.next(langCode);
  }

  setCurIsHiddenMenu(isHide: boolean) {
    this._curIsHiddenMenu.next(isHide);
  }

  setCurChangeLayoutIframe(ischange: boolean) {
    this._curChangeLayoutIframe.next(ischange);
  }

  setCurChangeLayoutIframeTop(data: any) {
    this._curChangeLayoutIframeTop.next(data);
  }

  setCurRemoveReward(reward: any) {
    this._curRemoveReward.next(reward);
  }

  setCurMessageError(reward: any) {
    this._curMessageError.next(reward);
  }

  setCurOldFilterUsage(filter: any) {
    this._curOldFilterUsage.next(filter);
  }

  setCurIsBackBrowser(isBack: boolean) {
    this._curIsBackBrowser.next(isBack);
  }

  setCurIsLoadingExchangeToken(isLoading: boolean) {
    this._curIsLoadingExchangeToken.next(isLoading);
  }

  setCurIsLoadingRemoveCart(isLoading: boolean) {
    this._curIsLoadingRemoveCart.next(isLoading);
  }

  setCurIsLoadingAddToCart(isLoading: boolean) {
    this._curIsLoadingAddToCart.next(isLoading);
  }
}
