import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, from, Subscription } from 'rxjs';

import { User } from '../shared/model/user.model';

import { SecurityService } from 'generated/identity-service/api/security.service';

import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Constant } from '../core/constant';
import { MessageService } from './message.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  logoutMess = null;
  sseObservable: Subscription;
  private tokenExpirationTimer: any;

  public readonly Permission_ReadUser = 'read_user';
  public readonly Permission_WriteUser = 'write_user';
  public readonly Permission_ReadRole = 'read_role';
  public readonly Permission_WriteRole = 'write_role';

  username: string = '';

  isAdmin: boolean = false;

  dataDecode: any;

  isLogout: boolean = false

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) { }

  login(username: string, password: string) {
    this.isLogout = false
    return this.securityService.getToken(environment.tenant, {
      username,
      password,
    });
  }

  autoLogin(status?: any) {
    this.isLogout = false
    const userToken: {
      access_token: string;
      refresh_token: string;
    } = JSON.parse(localStorage.getItem('claAccessToken'));
    if (!userToken) {
      return;
    }
    const loadedToken = new User(
      userToken.access_token,
      userToken.refresh_token
    );

    if (loadedToken.token) {
      this.user.next(loadedToken);

      const dataDecode: any = jwt_decode(loadedToken.token);
      let email = dataDecode['preferred_username'];
      this.dataDecode = dataDecode;

      if (dataDecode.hasOwnProperty('name')) {
        this.username = dataDecode['name'];
      } else {
        this.username = dataDecode['preferred_username'];
      }

      if (this.isTokenExpired(loadedToken.access_token)) {
        this.refreshToken(email, loadedToken.refresh_token);
      } else {
        this.translateService.setDefaultLang(dataDecode && dataDecode.language || "en");
        localStorage.setItem("languageDefault", dataDecode && dataDecode.language || 'en');
      }

      if (status === 401) {
        this.refreshToken(email, loadedToken.refresh_token);
      }
    }
  }

  logout(isAutoLogout: boolean, redirect = null) {
    this.messageService.closeMessages();
    this.isLogout = true;
    this.user.next(null);
    localStorage.removeItem('claAccessToken');
    this.modalService.dismissAll();
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    if (!isAutoLogout) {
      this.logoutMess = 'You have signed out of your account';
    } else {
      this.logoutMess =
        'Sorry, your session timed out due to inactivity. Please log-in again';
    }
    setTimeout(() => {
      this.router.navigate(['/', 'login'], redirect ? { queryParams: { returnUrl: redirect }} : {});
    });
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout(true);
    }, expirationDuration);
  }

  handleAuthentication(access_token: string, refresh_token: string) {
    const user = new User(access_token, refresh_token);
    const dataDecode = jwt_decode(access_token);
    const expireDuration = dataDecode['exp'] * 1000;
    this.user.next(user);
    localStorage.setItem('claAccessToken', JSON.stringify(user));
  }

  handleAuthenticationRewardStore(access_token: string, refresh_token: string) {
    const user = new User(access_token, refresh_token);
    const dataDecode: any = jwt_decode(access_token);
    const expireDuration = dataDecode['exp'] * 1000;
    this.user.next(user);
    this.translateService.setDefaultLang(dataDecode && dataDecode.language || 'en');
    localStorage.setItem("languageDefault", dataDecode && dataDecode.language || 'en');
    localStorage.setItem('rwdStoreAccessToken', JSON.stringify(user));
  }

  isTokenExpired(access_token) {
    try {
      const dataDecode = jwt_decode(access_token);
      const expireAt = dataDecode['exp'] * 1000;
      return new Date().getTime() >= expireAt;
    } catch (e) {
      return true;
    }
  }

  getUserData(access_token) {
    const dataDecode = jwt_decode(access_token);
    if (dataDecode.hasOwnProperty('name')) {
      this.username = dataDecode['name'];
    } else {
      this.username = dataDecode['preferred_username'];
    }

    if (dataDecode['resource_access'].hasOwnProperty('aidf')) {
      this.isAdmin = true;
    }
  }


  refreshToken(username: string, token: string) {
    return from(this.securityService
      .getToken(environment.tenant, {
        username,
        token,
      }).toPromise().then((resData) => {
        this.handleAuthentication(
          resData.access_token,
          resData.refresh_token
        );
        const dataDecode: any = jwt_decode(resData.access_token);
        this.translateService.setDefaultLang(dataDecode && dataDecode.language || 'en');
        localStorage.setItem("languageDefault", dataDecode && dataDecode.language || 'en');
        return resData;
      }).catch(() => {
        if (window.location.href.includes("web-store")) {
     
        } else {
          this.logout(true);
        }
        return null;
      }));
  }

  getUserInfo() {
    let tmplogo = this.username.split(' ');
    let tmplogoname = '';
    tmplogo.forEach((itm) => {
      tmplogoname = tmplogoname + itm.substring(0, 1);
    });
    return {
      fullname: this.username,
      username: tmplogoname,
      userId: this.dataDecode['sub'],
      permissions: this.dataDecode['resource_access'][environment.tenant].roles,
      email: this.dataDecode['email'],
      firstname: this.dataDecode['given_name'],
      lastname: this.dataDecode['family_name'],
      temppass: this.dataDecode['pw_temporary'],
      admin: this.dataDecode['admin']
    };
  }

  getNewUserInfo(token) {
    const dataDecode = jwt_decode(token);
    this.dataDecode = dataDecode;
    let tmpusername = '';
    let isRoleUser = false;
    if (dataDecode.hasOwnProperty('name')) {
      tmpusername = dataDecode['name'];
    } else {
      tmpusername = dataDecode['preferred_username'];
    }
    let tmplogo = this.username.split(' ');
    let tmplogoname = '';
    tmplogo.forEach((itm) => {
      tmplogoname = tmplogoname + itm.substring(0, 1);
    });
    if (dataDecode.hasOwnProperty("origin_login_url")) {
      isRoleUser = dataDecode["origin_login_url"].substring(dataDecode["origin_login_url"].lastIndexOf('/') + 1) === "login-cla";
    }
    //this.checkChangedPass()
    return {
      originLoginUrl: dataDecode["origin_login_url"],
      isRoleUser,
      userCode: dataDecode['preferred_username'],
      fullname: tmpusername,
      username: tmplogoname,
      userId: dataDecode['sub'],
      permissions: dataDecode['resource_access'] && dataDecode['resource_access'][environment.tenant] && dataDecode['resource_access'][environment.tenant].roles || [],
      email: dataDecode['email'],
      firstname: dataDecode['given_name'],
      lastname: dataDecode['family_name'],
      temppass: dataDecode['pw_temporary'],
      admin: dataDecode['admin'],
      orgId: dataDecode['orgId']
    };
  }

  checkPermissionRedirect() {
    const userToken: {
      access_token: string,
      refresh_token: string
    } = JSON.parse(localStorage.getItem('claAccessToken'))
    if (userToken && userToken.access_token) {
      const user = this.getNewUserInfo(userToken.access_token);
      const userPermission = user && user.permissions;
      if (userPermission && userPermission.length) {
        const dashboardFound = userPermission.find(x => x.toLowerCase() === 'das_view');
        if (dashboardFound) {
          this.router.navigateByUrl('dashboard');
        } else if (userPermission[0]?.split('_')[0]) {
          for (const property in Constant.URL_PERMISSION) {
            if (userPermission.includes(property)) {
              return this.router.navigateByUrl(Constant.URL_PERMISSION[property]);
            }
          }
        } else {
          this.router.navigateByUrl('account-setting');
        }
      } else {
        return this.router.navigateByUrl('login');
      }
    } else {
      return this.router.navigateByUrl('login');
    }
  }

  isCurrentTokenExpired() {
    let accessToken = this.getAccessToken();
    return accessToken.length > 0 ? this.isTokenExpired(accessToken) : true;
  }

  getAccessToken() {
    try {
      const userToken: {
        access_token: string;
      } = JSON.parse(localStorage.getItem('claAccessToken'));
      return userToken ? userToken.access_token : '';
    } catch (e) {
      return '';
    }
  }

  checkChangedPass() {
    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log(event)
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
        }
      });
  }
}
