/**
 * Order Service Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponse } from '../model/booleanResponse';
// @ts-ignore
import { OrderData } from '../model/orderData';
// @ts-ignore
import { OrderDataGetAll } from '../model/orderDataGetAll';
// @ts-ignore
import { OrderStatus } from '../model/orderStatus';
// @ts-ignore
import { RestError } from '../model/restError';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class OrderServiceService {

    protected basePath = 'http://localhost:19098/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * get all order by memberid
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param memberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOrder(xSAITENANT: string, xSAILANG: string, memberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderDataGetAll>;
    public getAllOrder(xSAITENANT: string, xSAILANG: string, memberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderDataGetAll>>;
    public getAllOrder(xSAITENANT: string, xSAILANG: string, memberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderDataGetAll>>;
    public getAllOrder(xSAITENANT: string, xSAILANG: string, memberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllOrder.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getAllOrder.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling getAllOrder.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/members/${this.configuration.encodeParam({name: "memberId", value: memberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders`;
        return this.httpClient.request<OrderDataGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all order
     * @param xSAITENANT 
     * @param itemName search reward with value
     * @param status search reward by column
     * @param page 
     * @param size 
     * @param sortBy 
     * @param sortDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOrderByAdmin(xSAITENANT: string, itemName?: string, status?: Array<OrderStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderDataGetAll>;
    public getAllOrderByAdmin(xSAITENANT: string, itemName?: string, status?: Array<OrderStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderDataGetAll>>;
    public getAllOrderByAdmin(xSAITENANT: string, itemName?: string, status?: Array<OrderStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderDataGetAll>>;
    public getAllOrderByAdmin(xSAITENANT: string, itemName?: string, status?: Array<OrderStatus>, page?: number, size?: number, sortBy?: string, sortDesc?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllOrderByAdmin.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (itemName !== undefined && itemName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>itemName, 'itemName');
        }
        if (status) {
            status.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'status');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDesc !== undefined && sortDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDesc, 'sortDesc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders`;
        return this.httpClient.request<OrderDataGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all order by ids
     * @param xSAITENANT 
     * @param id search order by list id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOrderByIdsForAdmin(xSAITENANT: string, id?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderDataGetAll>;
    public getAllOrderByIdsForAdmin(xSAITENANT: string, id?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderDataGetAll>>;
    public getAllOrderByIdsForAdmin(xSAITENANT: string, id?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderDataGetAll>>;
    public getAllOrderByIdsForAdmin(xSAITENANT: string, id?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getAllOrderByIdsForAdmin.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id) {
            id.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'id');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/get-all`;
        return this.httpClient.request<OrderDataGetAll>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get order data by orderId
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param orderId Order Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDetails(xSAITENANT: string, xSAILANG: string, orderId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderData>;
    public getOrderDetails(xSAITENANT: string, xSAILANG: string, orderId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderData>>;
    public getOrderDetails(xSAITENANT: string, xSAILANG: string, orderId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderData>>;
    public getOrderDetails(xSAITENANT: string, xSAILANG: string, orderId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling getOrderDetails.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling getOrderDetails.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderDetails.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<OrderData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Place an order by cartId
     * @param xSAITENANT 
     * @param xSAILANG 
     * @param memberId 
     * @param cartId Cart id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public placeAnOrder(xSAITENANT: string, xSAILANG: string, memberId: string, cartId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderData>;
    public placeAnOrder(xSAITENANT: string, xSAILANG: string, memberId: string, cartId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderData>>;
    public placeAnOrder(xSAITENANT: string, xSAILANG: string, memberId: string, cartId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderData>>;
    public placeAnOrder(xSAITENANT: string, xSAILANG: string, memberId: string, cartId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling placeAnOrder.');
        }
        if (xSAILANG === null || xSAILANG === undefined) {
            throw new Error('Required parameter xSAILANG was null or undefined when calling placeAnOrder.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling placeAnOrder.');
        }
        if (cartId === null || cartId === undefined) {
            throw new Error('Required parameter cartId was null or undefined when calling placeAnOrder.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cartId !== undefined && cartId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cartId, 'cartId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }
        if (xSAILANG !== undefined && xSAILANG !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-LANG', String(xSAILANG));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/members/${this.configuration.encodeParam({name: "memberId", value: memberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders`;
        return this.httpClient.request<OrderData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update order status
     * @param xSAITENANT 
     * @param orderId Order Id
     * @param status Order Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderStatus(xSAITENANT: string, orderId: string, status: OrderStatus, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BooleanResponse>;
    public updateOrderStatus(xSAITENANT: string, orderId: string, status: OrderStatus, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponse>>;
    public updateOrderStatus(xSAITENANT: string, orderId: string, status: OrderStatus, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponse>>;
    public updateOrderStatus(xSAITENANT: string, orderId: string, status: OrderStatus, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling updateOrderStatus.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrderStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateOrderStatus.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/status/${this.configuration.encodeParam({name: "status", value: status, in: "path", style: "simple", explode: false, dataType: "OrderStatus", dataFormat: undefined})}`;
        return this.httpClient.request<BooleanResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update order status
     * @param xSAITENANT 
     * @param memberId 
     * @param orderId Order Id
     * @param status status
     * @param sourceEvent Source Event
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePlaceOrderStatus(xSAITENANT: string, memberId: string, orderId: string, status: boolean, sourceEvent?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OrderData>;
    public updatePlaceOrderStatus(xSAITENANT: string, memberId: string, orderId: string, status: boolean, sourceEvent?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OrderData>>;
    public updatePlaceOrderStatus(xSAITENANT: string, memberId: string, orderId: string, status: boolean, sourceEvent?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OrderData>>;
    public updatePlaceOrderStatus(xSAITENANT: string, memberId: string, orderId: string, status: boolean, sourceEvent?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xSAITENANT === null || xSAITENANT === undefined) {
            throw new Error('Required parameter xSAITENANT was null or undefined when calling updatePlaceOrderStatus.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling updatePlaceOrderStatus.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updatePlaceOrderStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updatePlaceOrderStatus.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xSAITENANT !== undefined && xSAITENANT !== null) {
            localVarHeaders = localVarHeaders.set('X-SAI-TENANT', String(xSAITENANT));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/members/${this.configuration.encodeParam({name: "memberId", value: memberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "sourceEvent", value: sourceEvent, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/status/${this.configuration.encodeParam({name: "status", value: status, in: "path", style: "simple", explode: false, dataType: "boolean", dataFormat: undefined})}`;
        return this.httpClient.request<OrderData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
