/**
 * Reward Inventory Service Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WarehouseType = 'Brother' | 'Normal';

export const WarehouseType = {
    Brother: 'Brother' as WarehouseType,
    Normal: 'Normal' as WarehouseType
};

