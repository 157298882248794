export * from './backOfficeSystemSetting.service';
import { BackOfficeSystemSettingService } from './backOfficeSystemSetting.service';
export * from './customAttribute.service';
import { CustomAttributeService } from './customAttribute.service';
export * from './customAttributeValue.service';
import { CustomAttributeValueService } from './customAttributeValue.service';
export * from './externalGeography.service';
import { ExternalGeographyService } from './externalGeography.service';
export * from './geography.service';
import { GeographyService } from './geography.service';
export * from './internalSystemSetting.service';
import { InternalSystemSettingService } from './internalSystemSetting.service';
export * from './language.service';
import { LanguageService } from './language.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './security.service';
import { SecurityService } from './security.service';
export * from './systemSetting.service';
import { SystemSettingService } from './systemSetting.service';
export * from './tenant.service';
import { TenantService } from './tenant.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [BackOfficeSystemSettingService, CustomAttributeService, CustomAttributeValueService, ExternalGeographyService, GeographyService, InternalSystemSettingService, LanguageService, RoleService, SecurityService, SystemSettingService, TenantService, UserService];
