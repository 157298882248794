/**
 * Storage Service Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FileVisibility = 'Public' | 'Private' | 'Refurbishment' | 'Certify' | 'MLModel';

export const FileVisibility = {
    Public: 'Public' as FileVisibility,
    Private: 'Private' as FileVisibility,
    Refurbishment: 'Refurbishment' as FileVisibility,
    Certify: 'Certify' as FileVisibility,
    MlModel: 'MLModel' as FileVisibility
};

