import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ImageService,
  OrganizationService,
  OrganizationTypeService,
  UserService,
} from 'generated/user-service';
import { AuthService } from './service/auth.service';
import { StyleRenderer } from '@alyle/ui';
import { MasterDataService } from 'generated/masterdata-service';
import { AdjustmentReasonService, MemberGroupFilterService, MemberGroupService, MemberService } from 'generated/member-service';
import { EmailTemplateService, OrganizationTierService, PointToCurrencyService, ProgramService, RewardStoreService, RuleService } from 'generated/program-service';
import { CustomerPublicService } from 'generated/program-public-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ExchangeTokenService } from './service/token-exchange-faces-to-cla.service';
import { StorageService } from 'generated/storage-service';
import { AccrualEventService } from 'generated/accrual-event-service';
import { RewardInventoryService, StockInventoryService, WarehouseService } from 'generated/reward-inventory-service';
import { SecurityService } from 'generated/identity-service/api/security.service';
import { RoleService } from 'generated/identity-service/api/role.service';
import { SystemSettingService } from 'generated/identity-service/api/systemSetting.service';
import { GeographyService } from 'generated/identity-service/api/geography.service';
import { LanguageService } from 'generated/identity-service/api/language.service';
import { CustomAttributeValueService } from 'generated/identity-service/api/customAttributeValue.service';
import { BackOfficeSystemSettingService, ExternalGeographyService, InternalSystemSettingService } from 'generated/identity-service';
import { EmailTemplateAllService, EmailTemplateDetailService, ExtensionEntriesService, GetAllProductCategoriesService, GetAllProductsService, GetAllSeriesService } from 'generated/xpress-integration-service';
import { CLAPublicAPIService } from 'generated/cla-public-service';
import { FulfillmentService, OrderServiceService, RewardOrderService, RewardUsageService, ShippingService } from 'generated/order-service';
import { DashboardService } from 'generated/dashboard-service';
import { SourceSystemService } from 'generated/integration-service';
import { registerLocaleData } from '@angular/common';
import myLocaleZh from '@angular/common/locales/zh';
import myLocaleJa from '@angular/common/locales/ja';
import myLocaleKo from '@angular/common/locales/ko';
import myLocaleZhHK from '@angular/common/locales/zh-Hans-HK';
import myLocaleEn from '@angular/common/locales/en';
import myLocaleVi from '@angular/common/locales/vi';
import { MembershipImportService } from 'generated/brother-member-service';
import { OrgFeatureService } from 'generated/user-extension-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [StyleRenderer],
})
export class AppComponent implements OnInit {

  title = 'cla-webapp';
  isLoading: boolean = false;
  modalPwaPlatform: string | undefined;
  modalPwaEvent: any;
  constructor(
    readonly sRenderer: StyleRenderer,
    private authService: AuthService,
    public translate: TranslateService,
    private securityService: SecurityService,
    private userService: UserService,
    private roleService: RoleService,
    private orgTypeService: OrganizationTypeService,
    private orgListSerive: OrganizationService,
    private imageService: ImageService,
    private tenantService: SystemSettingService,
    private geographyService: GeographyService,
    private masterDataService: MasterDataService,
    private memberGroupService: MemberGroupService,
    private rewardService: RewardInventoryService,
    private programService: ProgramService,
    private organizationTierService: OrganizationTierService,
    private customerPublicService: CustomerPublicService,
    private accrualEventService: AccrualEventService,
    private _exchangeTokenService: ExchangeTokenService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private customAttributeValueService: CustomAttributeValueService,
    private warehouseService: WarehouseService,
    private extensionEntriesService: ExtensionEntriesService,
    private stockInventoryService: StockInventoryService,
    private memberGroupFilterService: MemberGroupFilterService,
    private backOfficeSystemSettingService: BackOfficeSystemSettingService,
    private getAllProductsService: GetAllProductsService,
    private rewardOrderService: RewardOrderService,
    private orderService: OrderServiceService,
    private claPublicService: CLAPublicAPIService,
    private rewardStoreService: RewardStoreService,
    private shippingService: ShippingService,
    private memberService: MemberService,
    private adjustmentReasonService: AdjustmentReasonService,
    private dashboardService: DashboardService,
    private emailTemplateService: EmailTemplateService,
    private pointToCurrencyService: PointToCurrencyService,
    private getAllProductCategoriesService: GetAllProductCategoriesService,
    private fulfillmentService: FulfillmentService,
    private ruleService: RuleService,
    private rewardUsageService: RewardUsageService,
    private sourceSystemService: SourceSystemService,
    private internalSystemSettingService: InternalSystemSettingService,
    private emailTemplateAllService: EmailTemplateAllService,
    private emailTemplateDetailService: EmailTemplateDetailService,
    private getAllSeriesService: GetAllSeriesService,
    private externalGeographyService: ExternalGeographyService,
    private membershipImportService: MembershipImportService,
    private orgFeatureService: OrgFeatureService,
    private _router: Router,
  ) {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        const curUrl = event.url;
        if (!curUrl.includes("web-store")) {
          this.authService.autoLogin();
        }
      });
    this.securityService.configuration.basePath =
      this.userService.configuration.basePath =
      this.orgTypeService.configuration.basePath =
      this.orgListSerive.configuration.basePath =
      this.imageService.configuration.basePath =
      this.tenantService.configuration.basePath =
      this.geographyService.configuration.basePath =
      this.roleService.configuration.basePath =
      this.masterDataService.configuration.basePath =
      this.memberGroupService.configuration.basePath =
      this.rewardService.configuration.basePath =
      this.programService.configuration.basePath =
      this.organizationTierService.configuration.basePath =
      this.customerPublicService.configuration.basePath =
      this.accrualEventService.configuration.basePath =
      this.storageService.configuration.basePath =
      this.languageService.configuration.basePath =
      this.customAttributeValueService.configuration.basePath =
      this.warehouseService.configuration.basePath =
      this.extensionEntriesService.configuration.basePath =
      this.stockInventoryService.configuration.basePath =
      this.memberGroupFilterService.configuration.basePath =
      this.backOfficeSystemSettingService.configuration.basePath =
      this.getAllProductsService.configuration.basePath =
      this.claPublicService.configuration.basePath =
      this.orderService.configuration.basePath =
      this.rewardOrderService.configuration.basePath =
      this.rewardStoreService.configuration.basePath =
      this.memberService.configuration.basePath =
      this.shippingService.configuration.basePath =
      this.adjustmentReasonService.configuration.basePath =
      this.dashboardService.configuration.basePath =
      this.emailTemplateService.configuration.basePath =
      this.pointToCurrencyService.configuration.basePath =
      this.getAllProductCategoriesService.configuration.basePath =
      this.ruleService.configuration.basePath =
      this.fulfillmentService.configuration.basePath =
      this.rewardUsageService.configuration.basePath =
      this.sourceSystemService.configuration.basePath =
      this.internalSystemSettingService.configuration.basePath =
      this.emailTemplateAllService.configuration.basePath =
      this.emailTemplateDetailService.configuration.basePath =
      this.getAllSeriesService.configuration.basePath =
      this.externalGeographyService.configuration.basePath =
      this.membershipImportService.configuration.basePath =
      this.orgFeatureService.configuration.basePath =
      '/api/v1';
      localStorage.removeItem('rwdStoreAccessToken');
  }

  ngOnInit() {
    // this.translate.setDefaultLang('en')
    // this.authService.autoLogin();
    registerLocaleData(myLocaleEn);
    registerLocaleData(myLocaleZh);
    registerLocaleData(myLocaleVi);
    registerLocaleData(myLocaleZhHK);
    registerLocaleData(myLocaleKo);
    registerLocaleData(myLocaleJa);
    if ('caches' in window) {
      caches.keys()
        .then(function (keyList) {
          return Promise.all(keyList.map(function (key) {
            return caches.delete(key);
          }));
        })
    }
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
    }
  }
}
