import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    _messages = new BehaviorSubject("");
    messages = this._messages.asObservable();

    _messages401 = new BehaviorSubject("");
    messages401 = this._messages401.asObservable();

    setMessages(message: any) {
        this._messages.next(message);
    }

    setMessages401(message: any) {
        this._messages401.next(message);
    }

    closeMessages() {
        this._messages.next('closeMessage');
        this._messages401.next('closeMessage');
    }
}