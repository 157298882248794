/**
 * Order Service Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Salutation = 'Mr' | 'Mrs' | 'Miss' | 'Ms';

export const Salutation = {
    Mr: 'Mr' as Salutation,
    Mrs: 'Mrs' as Salutation,
    Miss: 'Miss' as Salutation,
    Ms: 'Ms' as Salutation
};

