/**
 * SAI Common Component Identity Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PasswordPolicyType = 'Length' | 'UpperCase' | 'LowerCase' | 'SpecialCharacter' | 'PasswordHistory' | 'NotEmail' | 'NotUserName' | 'Digits' | 'Expiry';

export const PasswordPolicyType = {
    Length: 'Length' as PasswordPolicyType,
    UpperCase: 'UpperCase' as PasswordPolicyType,
    LowerCase: 'LowerCase' as PasswordPolicyType,
    SpecialCharacter: 'SpecialCharacter' as PasswordPolicyType,
    PasswordHistory: 'PasswordHistory' as PasswordPolicyType,
    NotEmail: 'NotEmail' as PasswordPolicyType,
    NotUserName: 'NotUserName' as PasswordPolicyType,
    Digits: 'Digits' as PasswordPolicyType,
    Expiry: 'Expiry' as PasswordPolicyType
};

